<template>
  <b-modal
    v-if="shareData"
    id="share-property-modal"
    ref="share-property-modal"
    title="مشاركة العقار"
    hide-footer
    hide-header
    centered
  >
    <button
      @click="hideModal"
      type="button"
      aria-label="Close"
      class="close close-modal-btn"
    >
      <span class="hi-close"></span>
    </button>

    <template v-if="shareData.name">
      <h4>شارك قائمة العقارات الآن</h4>
      <p class="info-text mb-3">
        شارك رابط قائمة العقارات عبر واحدة من هذه المنصات
      </p>
    </template>
    <template v-else>
      <h4>شارك العقار الآن</h4>
      <p class="info-text mb-3">شارك رابط العقار عبر واحدة من هذه المنصات</p>
    </template>

    <div class="social-links-wrap">
      <vue-goodshare-facebook
        title_social="فيسبوك"
        :page_description="pageDescription"
        has_icon
        @click.native="shareRealEstate('facebook')"
      ></vue-goodshare-facebook>
      <vue-goodshare-twitter
        title_social="تويتر"
        has_icon
        @click.native="shareRealEstate('twitter')"
      ></vue-goodshare-twitter>
      <vue-goodshare-linkedIn
        title_social="لينكد ان"
        :page_description="pageDescription"
        has_icon
        @click.native="shareRealEstate('linkedin')"
      ></vue-goodshare-linkedIn>
      <ShareNetwork
        class="mx-1"
        network="WhatsApp"
        :url="url"
        :title="pageTitle"
        :description="pageDescription"
        @click.native="shareRealEstate('whatsapp')"
      >
        <div
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <img
            class="mb-1"
            width="65"
            height="65"
            src="https://img.icons8.com/color/452/whatsapp--v1.png"
            alt="What's App Icon"
          />
          <span class="text-dark">واتس آب</span>
        </div>
      </ShareNetwork>
      <vue-goodshare-email
        title_social="الإيميل"
        :mail_subject="pageTitle"
        has_icon
        @click.native="shareRealEstate('email')"
      ></vue-goodshare-email>
      <!-- <vue-goodshare-sms has_icon title_social="SMS" /> -->
    </div>

    <!-- </b-collapse> -->
  </b-modal>
</template>

<script>
// Config File
import config from '@/config'
import { realEstateService } from '../../services'
import VueGoodshareFacebook from 'vue-goodshare/src/providers/Facebook.vue'
import VueGoodshareTwitter from 'vue-goodshare/src/providers/Twitter.vue'
import VueGoodshareLinkedIn from 'vue-goodshare/src/providers/LinkedIn.vue'
import VueGoodshareEmail from 'vue-goodshare/src/providers/Email.vue'
// import VueGoodshareWhatsApp from 'vue-goodshare/src/providers/WhatsApp.vue';
import { mapState } from 'vuex'

//Style
import '@/styles/property/social-links.scss'

export default {
  components: {
    VueGoodshareFacebook,
    VueGoodshareTwitter,
    VueGoodshareLinkedIn,
    VueGoodshareEmail
    // VueGoodshareWhatsApp,
  },
  props: ['shareData'],
  data() {
    return {}
  },
  mounted() {},
  computed: {
    ...mapState('accountState', ['user']),
    url() {
      if (this.shareData.name) {
        return location.origin + '/properties/' + this.shareData._id
      } else {
        return location.origin + '/property/' + this.shareData._id + '/0'
      }
    },
    payTypeOptions() {
      return config.payTypeOptions
    },
    pageTitle() {
      if (this.shareData.name) {
        return this.shareData.name
      } else {
        let type = this.shareData.type.nameAr
        let status = this.shareData.status.nameAr
        let payType =
          this.shareData.status.nameEn == 'rent' && this.shareData.payType
            ? '/' + this.payTypeOptions[this.shareData.payType].text
            : ''

        return (
          type +
          ' ' +
          status +
          ' - ' +
          this.numberWithCommas(this.shareData.price) +
          ' ريال' +
          payType
        )
      }
    },
    pageDescription() {
      return this.shareData.notes
    }
  },

  methods: {
    hideModal() {
      this.$refs['share-property-modal'].hide()
    },
    numberWithCommas(x) {
      return realEstateService.numberWithCommas(x)
    },
    shareRealEstate(platform) {
      const data = {
        realestate: this.shareData._id,
        user: this.user?._id,
        platform
      }
      return realEstateService.shareRealEstate(data)
    }
  }
}
</script>
